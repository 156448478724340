//This is Blue Gang's file
import React from 'react';
import logo from '../images/ODDSLogoBlue.png';

class HomeFrame extends React.Component {

	render() {
		return (
			<div className="HomeFrame-panel">
				<div className="HomeFrame-container">
                    <div>
						<center><img className="logo" src={logo} /></center>
					</div>
					<div>
                    	<h1>Cryptocurrency Analytics</h1>
					</div>
				</div>
				<div className="HomeFrame-container">
					<div>
						<h2 className="analysis-title"><a href="/hrh">Historical Return<br></br>Histogram</a></h2>
					</div>
					<div>
						<h2 className="analysis-title"><a href="/optvalue">Option Valuation<br></br>Analytics</a></h2>
					</div>
				</div>
			</div>
      
		);
	}
}

export default HomeFrame;