import './App.css';
import Header from './components/Header';
import HomeFrame from './components/HomeFrame';
import React from 'react';

class App extends React.Component {

	constructor(props) {
		super(props);

		this.onPanelChange = this.onPanelChange.bind(this);
		this.state = {
			histogramData: {counted: [], sd: [], theo: [] },
			statistics: {
				ticker: null,
				currentPrice: null,
				targetPrice: null,
				tradingDays: null,
				probBelow: null,
				probAbove: null,
				count: null,
				std: null,
				mean: null
			},
		};

	}

	onPanelChange(data) {
		this.setState({ statistics: data.statistics, histogramData: data });
	}

	render() {
		return (
			<body>
				<div className="wrapper"> 
					<div className="main">
						<div className="content-container">
							<HomeFrame />  
						</div>
					</div>
				</div>
			</body>
		);
	} 
}

export default App;
