import React from 'react';
import logo from '../images/ODDSLogoBlue-73.png';

function Header() {
	return(
		<div className="header">
			<div className="logo-container">
				<img className="logo" src={logo} />
				<h1 className="logo-text">Cryptocurrency Probability Calculator</h1>
			</div>
		</div>
	);
}

export default Header;